import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dialog, CircularProgress, styled } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    '& .MuiDialog-paper': {
      width: '100vw',
      margin: '1rem',
      overflowY: 'hidden',
    },
  },
}))

const StyledCloseIcon = styled(CloseOutlinedIcon)({
  position: 'absolute',
  top: 0,
  right: 0,
  margin: '5px',
  cursor: 'pointer',
  color: '#808080',
})

const FrameContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#fcfbf3',
  padding: '32px 20px',
  width: '500px',
  height: '750px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    padding: '32px 0',
    width: '100%',
    height: '60vh',
  },
  '& iframe': {
    width: '100%',
    height: '100%',
  },
}))

const TrustPilotModal = ({ url, open, onClose }) => {
  const [isLoading, setIsLoading] = useState(true)

  const handleOnLoad = () => {
    setIsLoading(false)
  }

  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledCloseIcon onClick={onClose} />
      <FrameContainer>
        <CircularProgress sx={{ display: isLoading ? 'block' : 'none' }} />
        <iframe
          style={{ display: isLoading ? 'none' : 'block' }}
          id="trustpilot-embed"
          title="trustpilot-review"
          src={url}
          onLoad={handleOnLoad}
        />
      </FrameContainer>
    </StyledDialog>
  )
}

TrustPilotModal.propTypes = {
  url: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default TrustPilotModal
